<template>
  <div>

    <account-form
      v-if="isAddNewAccountSidebarActive"
      :is-add-new-account-sidebar-active.sync="isAddNewAccountSidebarActive"
      :account="account"
      @form-submitted="formSubmitted"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>

            <v-select
              v-model="pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Press ENTER to search"
                @keyup.enter="refAccountsTable.refresh();"
              />

              <b-button
                variant="primary"
                @click=" openSidebar "
              >
                <span class="text-nowrap">Add Account</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="account-table"
        ref="refAccountsTable"
        class="position-relative"
        :items=" getAccountsList "
        responsive
        :fields=" tableColumns "
        primary-key="id"
        :sort-by.sync=" orderBy "
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync=" orderDesc "
        style="min-height:110px"
      >
        <!-- Column: Name -->
        <template #cell(name)=" data ">
          <b-link
            :to=" { name: 'account-details', params: { id: data.item.id } } "
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)=" data ">
          <b-dropdown
            variant="link"
            no-caret
            :right=" $store.state.appConfig.isRTL "
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click=" editAccount(data.item) ">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model=" pageNumber "
              :total-rows=" totalAccounts "
              :per-page=" pageSize "
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import AccountForm from '@/modules/account/components/AccountForm.vue';
import useAccountList from '@/modules/account/composables/useAccountList';
import vSelect from 'vue-select';

export default {
  setup() {
    const {
      dataMeta,
      getAccountsList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refAccountsTable,
      searchQuery,
      tableColumns,
      totalAccounts,
    } = useAccountList();

    const emptyAccount = { id: null, name: null };

    const isAddNewAccountSidebarActive = ref(false);
    const account = ref({ ...emptyAccount });

    const editAccount = (accountSelected) => {
      account.value = { ...accountSelected };
      isAddNewAccountSidebarActive.value = true;
    };

    const formSubmitted = () => {
      isAddNewAccountSidebarActive.value = false;
      account.value = { ...emptyAccount };
      refAccountsTable.value.refresh();
    };

    const openSidebar = () => {
      account.value = { ...emptyAccount };
      isAddNewAccountSidebarActive.value = true;
    };

    return {
      // Reactive
      account,
      isAddNewAccountSidebarActive,

      //Method
      editAccount,
      formSubmitted,
      openSidebar,

      // UseAccountsList
      dataMeta,
      getAccountsList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refAccountsTable,
      searchQuery,
      tableColumns,
      totalAccounts,
    };
  },
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    AccountForm,
    vSelect,
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

#account-table {
  min-height: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>