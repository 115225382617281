<template>
  <b-sidebar
    id="add-new-account-sidebar"
    :visible="isAddNewAccountSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-account-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ accountData.id ? 'Update' : 'Add New' }} Account
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <validation-observer ref="newAccountForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validationForm()"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="accountData.name"
                :state="errors.length > 0 ? false : null"
                name="name"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Is JURA Account"
            label-for="is-jura-acc"
          >
            <b-form-checkbox
              @change="accountTypeHasChange('jura')"
              v-model="accountData.isJuraAcc"
              name="is-jura-acc"
              switch
            />
          </b-form-group>

          <b-form-group
            label="Is GTA Office Account"
            label-for="is-gta-acc"
          >
            <b-form-checkbox
              @change="accountTypeHasChange('gta')"
              v-model="accountData.isGtaAcc"
              name="is-gta-acc"
              switch
            />
          </b-form-group>

          <b-form-group
            label="Is Frontier Account"
            label-for="is-frontier-acc"
          >
            <b-form-checkbox
              @change="accountTypeHasChange('frontier')"
              v-model="accountData.isFrontierAcc"
              name="is-frontier-acc"
              switch
            />
          </b-form-group>

          <b-form-group
            label="Is Bank Account"
            label-for="is-bank-acc"
          >
            <b-form-checkbox
              @change="accountTypeHasChange('bank')"
              v-model="accountData.isBankAcc"
              name="is-bank-acc"
              switch
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ accountData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  BButton,
  BFormCheckbox,
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import vSelect from 'vue-select';
import useAccount from '@/modules/account/composables/useAccount';

export default {
  props: {
    isAddNewAccountSidebarActive: {
      type: Boolean,
      required: true,
    },
    account: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const { createAccount, updateAccount } = useAccount();
    const accountData = ref(props.account);
    const newAccountForm = ref(null);
    const isLoading = ref(false);

    const resetForm = () => {
      newAccountForm.value.reset();
    };
    const accountTypeHasChange = (type) => {
      switch (type) {
        case 'jura':
          accountData.value.isGtaAcc = false;
          accountData.value.isFrontierAcc = false;
          accountData.value.isBankAcc = false;
          break;
        case 'gta':
          accountData.value.isJuraAcc = false;
          accountData.value.isFrontierAcc = false;
          accountData.value.isBankAcc = false;
          break;
        case 'frontier':
          accountData.value.isJuraAcc = false;
          accountData.value.isGtaAcc = false;
          accountData.value.isBankAcc = false;
        case 'bank':
          accountData.value.isJuraAcc = false;
          accountData.value.isGtaAcc = false;
          accountData.value.isFrontierAcc = false;
          break;
      }
    };

    const validationForm = async () => {
      if (isLoading.value) return;
      isLoading.value = true;

      const valid = await newAccountForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      const response = accountData.value.id
        ? await updateAccount(accountData.value)
        : await createAccount(accountData.value);

      isLoading.value = false;
      if (response !== null) ctx.emit('form-submitted');
    };

    return {
      // Reactive
      accountData,
      newAccountForm,
      isLoading,

      // Method
      accountTypeHasChange,
      resetForm,
      validationForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
